import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
          
          <path d="M4725 7863 c-176 -7 -452 -24 -472 -29 l-23 -6 0 -1049 c0 -576 3
-1050 8 -1052 18 -12 359 -30 562 -30 249 -1 340 11 498 62 202 66 332 174
408 336 72 156 70 338 -6 499 -39 85 -144 194 -212 222 -27 10 -48 22 -48 26
0 4 17 18 38 32 89 57 175 186 202 305 18 76 8 231 -20 300 -66 166 -222 288
-446 346 -82 21 -348 50 -404 43 -8 -1 -46 -3 -85 -5z m387 -332 c148 -48 220
-134 219 -264 0 -36 -7 -83 -16 -104 -21 -50 -79 -109 -135 -138 -98 -50 -142
-58 -372 -62 l-218 -5 0 295 0 296 48 4 c26 3 128 3 227 2 152 -2 190 -6 247
-24z m13 -895 c183 -52 267 -148 268 -305 1 -126 -52 -208 -169 -265 -93 -46
-187 -58 -421 -54 l-208 3 -3 324 -2 323 237 -5 c182 -3 252 -8 298 -21z"/>
<path d="M2822 7743 c-22 -54 -84 -206 -137 -338 -53 -132 -102 -253 -110
-270 -7 -16 -22 -54 -34 -83 -40 -98 -460 -1131 -489 -1205 -8 -20 -13 -38
-11 -40 2 -2 72 -30 156 -64 l151 -61 42 107 c23 58 67 169 97 246 31 77 64
160 73 185 l18 45 449 3 449 2 13 -37 c18 -50 213 -547 217 -552 4 -5 306 117
317 128 4 4 -174 462 -396 1017 l-404 1009 -180 3 -180 2 -41 -97z m218 -358
c0 -8 4 -23 10 -33 10 -17 97 -240 195 -497 29 -77 63 -162 74 -188 40 -94 64
-87 -294 -86 -268 0 -315 3 -315 15 0 15 11 45 184 484 62 157 116 293 119
303 8 21 27 23 27 2z"/>
<path d="M6715 7782 c-148 -70 -263 -123 -375 -174 -47 -21 -93 -43 -102 -48
-9 -5 -66 -32 -126 -59 -208 -93 -272 -124 -272 -130 0 -8 16 -18 126 -79 101
-56 105 -59 309 -174 83 -47 173 -98 200 -113 28 -16 66 -38 85 -50 19 -12 67
-39 105 -60 94 -51 128 -76 118 -85 -4 -5 -73 -38 -153 -74 -79 -37 -148 -70
-153 -75 -5 -5 18 -24 50 -42 104 -61 133 -78 276 -167 78 -48 224 -136 325
-197 100 -60 182 -116 182 -122 0 -7 -15 -19 -32 -26 -102 -43 -177 -81 -173
-87 3 -5 24 -18 47 -30 24 -12 93 -52 153 -89 238 -146 467 -281 476 -281 5 0
9 -4 9 -10 0 -5 4 -10 10 -10 5 0 87 -47 182 -105 95 -58 180 -108 188 -112 8
-4 66 -38 128 -76 62 -38 115 -67 117 -65 4 4 -41 61 -143 183 -96 114 -396
478 -442 535 -19 24 -81 99 -137 166 -57 67 -103 127 -103 132 0 12 32 32 135
87 39 21 74 41 78 45 5 5 -49 64 -119 131 -71 68 -180 174 -244 235 -63 62
-143 138 -177 171 -35 32 -63 65 -63 74 0 8 12 22 28 31 15 9 46 30 70 47 l43
32 -153 227 c-84 125 -155 229 -159 232 -3 3 -45 65 -93 138 -49 72 -92 132
-95 131 -3 0 -60 -26 -126 -57z"/>
<path d="M5320 4475 l0 -576 63 3 62 3 3 573 2 572 -65 0 -65 0 0 -575z"/>
<path d="M3590 4935 l0 -55 175 0 175 0 0 -490 0 -491 68 3 67 3 3 488 2 487
176 0 175 0 -3 53 -3 52 -417 3 -418 2 0 -55z"/>
<path d="M4750 4721 c-95 -19 -192 -94 -241 -187 -40 -73 -52 -146 -47 -271 4
-95 8 -116 34 -167 38 -78 101 -141 172 -174 50 -24 69 -27 162 -27 88 0 112
4 150 22 57 29 140 100 140 122 0 9 -16 27 -36 39 l-36 24 -37 -36 c-49 -47
-98 -66 -173 -66 -116 1 -200 65 -232 179 -28 100 -53 91 259 91 l275 0 0 68
c0 117 -48 251 -112 313 -56 53 -192 88 -278 70z m153 -122 c15 -7 40 -32 56
-56 29 -44 56 -136 45 -154 -3 -5 -95 -9 -206 -9 l-201 0 7 46 c15 114 115
200 221 190 28 -2 62 -10 78 -17z"/>
<path d="M5930 4721 c-99 -22 -197 -101 -243 -195 -39 -82 -50 -147 -44 -263
4 -91 9 -113 37 -170 40 -81 95 -137 169 -171 49 -24 69 -27 161 -27 89 0 112
3 152 23 58 29 138 98 138 120 0 10 -16 27 -35 40 l-36 23 -32 -32 c-50 -48
-103 -69 -177 -69 -135 0 -227 89 -242 235 l-3 30 274 3 274 2 -5 98 c-6 108
-28 176 -80 249 -57 78 -202 127 -308 104z m170 -131 c39 -28 79 -106 82 -162
l3 -43 -202 -3 c-232 -3 -220 -9 -183 91 22 59 67 108 120 132 48 21 139 14
180 -15z"/>
<path d="M6712 4715 c-171 -38 -271 -208 -259 -440 7 -147 59 -259 149 -329
80 -61 246 -72 348 -24 89 42 161 124 175 200 l6 34 -62 -1 c-61 0 -62 -1 -73
-33 -14 -41 -61 -91 -103 -108 -39 -17 -131 -18 -170 -3 -104 39 -161 195
-135 372 15 100 45 163 98 200 109 76 274 23 307 -100 l11 -43 64 0 64 0 -7
37 c-33 176 -217 283 -413 238z"/>
<path d="M7534 4720 c-171 -36 -274 -173 -290 -385 -14 -203 93 -384 257 -429
162 -46 316 7 400 137 54 83 69 142 69 262 0 157 -35 252 -121 329 -80 73
-210 108 -315 86z m139 -111 c97 -27 157 -128 165 -276 10 -209 -76 -333 -231
-333 -142 1 -227 112 -227 298 0 234 118 360 293 311z"/>
<path d="M8443 4720 c-48 -10 -108 -39 -143 -70 l-25 -22 -3 41 -3 41 -65 0
-64 0 2 -402 3 -403 62 -3 62 -3 3 305 c3 291 4 306 24 333 35 47 95 74 167
75 74 0 115 -17 149 -64 23 -33 23 -37 26 -340 l3 -308 64 0 64 0 3 293 3 292
30 44 c42 63 80 82 158 82 81 0 130 -22 154 -70 16 -31 19 -75 23 -336 l5
-300 60 0 60 0 3 290 c2 185 -1 309 -8 341 -27 126 -111 188 -254 188 -95 0
-146 -19 -216 -82 l-46 -40 -34 40 c-18 22 -52 49 -74 58 -51 23 -138 31 -193
20z"/>
<path d="M9687 4720 c-122 -22 -216 -121 -217 -228 0 -118 71 -175 294 -233
130 -35 146 -44 170 -98 17 -36 17 -44 5 -78 -49 -130 -342 -100 -361 37 -3
24 -7 25 -70 28 -62 3 -68 1 -68 -17 0 -29 34 -102 64 -138 80 -95 248 -130
400 -82 145 44 215 182 156 303 -35 72 -113 113 -316 166 -118 31 -162 81
-134 150 29 71 114 103 209 80 55 -13 105 -59 117 -105 6 -23 10 -25 70 -25
62 0 64 1 64 25 0 69 -72 160 -156 195 -51 22 -161 32 -227 20z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
